header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;

}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/*-------------CTA---------------------*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*-------------Icons---------------------*/

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    bottom: 3rem;
}


.header__socials::after{
    content : '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);

}

/*-------------Main Image---------------------*/

.me{
    background: linear-gradient(#161919, transparent);
    width: 19rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 9.5rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

#hoverbw {
    filter: none;
    -webkit-filter: grayscale(0);
    transition: var(--transition);
}

#hoverbw:hover {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    
}




/*-------------Scroll Down---------------------*/
.scroll_down{
    position: absolute;
    right: 2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*-------------------- Media Queries (Medium sized)-----*/

@media screen and (max-width: 1024px){
    header{
        height: 68vh;
    }

    
 }

 /*-------------------- Media Queries (Small sized)-----*/

 @media screen and (max-width: 600px){
    header{
        height: 100vh;
    }

    .header__socials,
    .scroll_down{
        display: none;
    }
 }
    