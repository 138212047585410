.container.contact__container{
    width: 73%;
    display: grid;
    grid-template-columns: max-content;
    gap: 2.5rem;
}

.contact__options{
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}

.contact__option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover{
    background: transparent;
    border-color: var(--color-bg-variant) ;
}


.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}  

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1.1rem;
}

.contact__option h5{
    color: var(--color-light);
}


/*-------------------- Media Queries (Medium sized)-----*/

@media screen and (max-width: 1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contact__options{
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }


 }

 /*-------------------- Media Queries (Small sized)-----*/

 @media screen and (max-width: 600px){
    .container.contact__container{
        grid-template-columns: 1fr;
        width: var(--container-width-sm);
    }
    .contact__options{
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
 }